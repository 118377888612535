.sigContainer {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #fff;
}

.sigPad {
    width: 100%;
    height: 100%;
}